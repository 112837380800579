import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const session = Vue.prototype.$session;
const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/Login.vue')
  },
  {
    path: '/payments/card/:card',
    name: 'CardList',
    props:true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Payments/PaymentsOptions.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{auth:true},
    children:[
      {
        path: '/',
        name: 'Map',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Devices/Map.vue')
      },
      {
        path: '/devices/list',
        name: 'Devices',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Devices/List.vue')
      },
      {
        path: '/drivers',
        name: 'DriversList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Drivers/List.vue')
      },
      {
        path: '/users',
        name: 'UsersList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Users/List.vue')
      },
      {
        path: '/users/profile',
        name: 'UsersProfile',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Users/Profile.vue')
      },
      {
        path: '/cards',
        name: 'CardList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Cards/List.vue')
      },
      
    ]
  },
  

]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // session.remove("rieggoToken");
  // localStorage.removeItem("rieggoToken");
  
	if(!session.exists("session") && localStorage.getItem("session")){
		let token=localStorage.getItem("session");
		let userData=localStorage.getItem("user");
		session.set("session",token);
		session.set("user",JSON.parse(userData));    
	}
	let token=session.exists("session");
  let auth=to.matched.some(record=> record.meta.auth);
  if (auth && !token) {
    next('/login')
  }else if(!auth && token){
    next('/')
  }else  next()
  
 
})
export default router
