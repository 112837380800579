<template>
  <v-app class="overflow-hidden">
    <v-app-bar
      app
      absolute
      color="secondary"
    >
      <v-app-bar-nav-icon class="secondary white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-x-transition"
        bottom
        right
        class="menu-rounded"       
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn            
            small
            text
            v-bind="attrs"
            v-on="on"
            fab 
            dark
            outlined
          >
            <v-icon  small>fa-user</v-icon>
          </v-btn>
        </template>
        <v-card class="card-rounded" width="350px">
          <v-card-title primary-title>
            
            <span >
              <v-avatar
                size="50"
                color="grey lighten-3"
              >
                <v-img :src="baseUrl+user.avatar" v-if="user.avatar"></v-img>
                <v-icon color="primary" v-else>fa-user</v-icon>
                
              </v-avatar>
              <span class="ma-5 text-caption" > {{user.first_name}} {{user.last_name}} </span>
              
            </span>
            
          </v-card-title>
          <v-card-text>
            <v-list elevation="0" color="primary--text">
              <v-list-item
                  to="/users/profile"
                  
                >
                  <v-list-item-avatar>
                    <v-icon small>fa-user</v-icon>
                    
                  </v-list-item-avatar>
                  <v-list-item-title>Perfil</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list-item
                @click="logOut()"
                
              >
                <v-list-item-avatar>
                  <v-icon small>fa-power-off</v-icon>
                  
                </v-list-item-avatar>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary 
      
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <v-img src="@/assets/CRECER EN COLECTIVO AC[3354].png"></v-img>
      </v-sheet>

      <v-divider></v-divider>
      <v-list >
        <v-list-item
          v-for="(item,index) in links"
          :key="index"
          link
          :to="item.link"
          color="secondary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main
      id="scrolling-techniques-7"
      class="grey lighten-3"
      height="100%"
    >
      
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import User from '@/services/Users';
export default {
  name: 'Home',
  components: {
    
  },
  data:()=>({
    menuActive:false,
    drawer:false,
    baseUrl:process.env.VUE_APP_SERVER_URL,
    links:[
      {icon:"fa-map-marked-alt",text:"Mapa ",link:"/"},
      {icon:"fa-motorcycle",text:"Vehiculos",link:"/devices/list"},
      {icon:"fa-portrait",text:"Conductores",link:"/drivers"},      
      {icon:"fa-credit-card",text:"Tarjetas",link:"/cards"},
      {icon:"fa-users",text:"Usuarios",link:"/users"}
    ]
  }),
  sockets: {
    
  },
  methods:{
    async loadUserData(){
      try {
        let res=await User.getMyInfo();
        this.$store.commit('setUser', res);
      } catch (error) {
        console.log(error);
      }
      
    },
    logOut(){
      this.$session.remove("session");
      localStorage.removeItem("session");
      window.location.href="/login";
    }
  },
  created(){
    this.loadUserData();
  },
  computed:{
    user(){
      return this.$store.state.user;
    }
  }
}
</script>
</style>
