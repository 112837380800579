import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#171548',
        secondary: '#0F75BC',
        accent: '#460FBC',
        error: '#E10B5F',
        info: '#00C0D0',
        success: '#2CD897',
        warning: '#EAA72A'
      },
    },
  },
    lang: {
      locales: { es },
      current: 'es',
    },
  icons: {
    iconfont: 'fa',
  },
});
