import Vue from 'vue'
import App from './App.vue'
import './session'
import router from './router'

import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './components/maps';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client'
import moment from "moment";
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
const socket = io(process.env.VUE_APP_SOCKET_URL);
Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false
moment.locale('es')



Vue.component('GmapCluster', GmapCluster)
Vue.prototype.$moment=moment;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
