import Connection from './Connection'
const URL = process.env.VUE_APP_SERVER_URL+"users";
class Devices extends Connection{
    
    constructor(){

    }
    static async store(data){
        let result=(await this.post(URL,data)).data;
        return result;
    }
    static async update(id,data){
        let url=URL+"/"+id
        let result=(await this.put(url,data)).data;
        return result;
    }
    static async getUsers(){
        let url=URL
        let result=(await this.get(url)).data;
        return result;
    }    
    static async updateAvatar(data){
        let url=URL+"/my/avatar"
        const formData = new FormData();
        formData.append("image",data.image);
        let result=(await this.put(url,formData,{'Content-Type': 'multipart/form-data'})).data;
        return result;
    }    
    static async updateMe(data){
        let url=URL+"/update/me"
        let result=(await this.put(url,data)).data;
        return result;
    }   
    static async updatePass(data){
        let url=URL+"/update/pass"
        let result=(await this.put(url,data)).data;
        return result;
    }
    static async getMyInfo(form){
        let url=URL+"/getmyinfo"
        let result=(await this.get(url,form)).data;
        return result;
    }
    static async auth(form){
        let url=URL+"/auth"
        let result=(await this.post(url,form)).data;
        if(typeof Storage !=='undefined' && !!result.token){
            if(!!form.rememberme){
                localStorage.session=result.token;
            }
        }
        return result;
    }
}
export default Devices;